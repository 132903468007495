import React from "react";
import { Link } from "gatsby";

const Layout = props => {
  const { title, children } = props;
  const [toggleNav, setToggleNav] = React.useState(false);

  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div id="menu" className="site-head-container">
          <a
            className="nav-burger"
            href={`#menu`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>{" "}
              <div className="hamburger-text-menu-text hidden">Menu</div>
            </div>
          </a>
          <nav id="swup" className="site-head-left">
            <ul className="nav" role="menu">
              <li className="nav-home " role="menuitem">
                <Link to={"https://shop.grainlab.ro/"}>Shop</Link>
              </li>
              {/* <li className='nav-home ' role='menuitem'>
                <Link to={`/oferte-curente/`}>Oferte</Link>
              </li> */}
              <li className="nav-home " role="menuitem">
                <Link to={`/tags/services/`}>Servicii</Link>
              </li>
              <li className="nav-about" role="menuitem">
                <Link to={`/desprenoi/`}>Despre noi</Link>
              </li>
              {/* <li className='nav-tags' role='menuitem'>
                <Link to={`/tags`}>Subiecte</Link>
              </li> */}
              <li className="nav-elements" role="menuitem">
                <Link to={`/preturi/`}>Prețuri</Link>
              </li>
              <li className="nav-elements" role="menuitem">
                <Link to={`/workshops/`}>Workshopuri</Link>
              </li>
              {/* <li className='nav-elements' role='menuitem'>
                <Link to={`/fotografi/`}>Susține și pe alții</Link>
              </li> */}
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              {/* {title} */}
              <img
                className="site-head-logo-image"
                src="/logo.png"
                alt="logo"
              />
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              <a
                href="https://www.facebook.com/grainlab.ro/"
                title="Facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              <a
                href="https://www.instagram.com/grainlab_ro/"
                title="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        <blockquote>
          <p>
            <a
              href="https://g.page/grainlab-ro?share"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ne găsiți pe strada Ion Rațiu nr 10, împreună cu Zorki Photo
              Caffe.
            </a>
          </p>
          <p>Avem deschis laboratorul de luni până vineri, între orele 12-20</p>
          <a href="tel:0774686512">0774 686 512</a>
        </blockquote>
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link>
      </footer>
    </div>
  );
};

export default Layout;
